.factory-overview-container {
    padding: 4rem 2rem;
    text-align: center;
    color: #fff;
    width: 100vw;
}

.main-title {
    margin-bottom: 1rem;

    animation: fadeInUp 3s ease-out;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.subtitle {
    margin-bottom: 1.5rem;
    color: #ccc;
    font-size: 1.1rem;
}

.factory-options {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.factory-card {
    background-color: #222;
    padding: 0.5rem;
    border-radius: 1rem;
    width: 280px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    text-decoration: none;
    color: white;
    border: 1px solid #333;
}

.factory-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px #0ff;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.soon-tag {
    font-size: 0.99rem;
    background: #0066ff;
    color: white;
    padding: 2px 6px;
    margin-left: 6px;
    border-radius: 4px;
}

.factory-info-cards,
.factory-options {
    gap: 2rem;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem;
}

.info-card {

    border-radius: 1rem;
    padding: 0.5rem;
    width: 280px;
    transition: transform 0.2s ease;
}

.info-card:hover {
    transform: translateY(-5px);
}

.info-card h3 {
    margin-bottom: 0.75rem;
    color: #fff;
}



.section-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #fff;
    background: linear-gradient(to right, #00f0ff, #ff00cc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-divider {
    border: none;
    height: 2px;
    background: linear-gradient(to right, #00f0ff, transparent, #00f0ff);
    margin: 4rem auto;
    width: 80%;
}

.glow-divider {
    height: 2px;
    background: linear-gradient(to right, #00f0ff, #ff00cc);
    margin: 2rem auto;
    border: none;
    width: 60%;
    opacity: 0.6;
}

.factory-info-section {
    margin-bottom: 4rem;
}

.factory-selector-section {
    padding-top: 2rem;
}

@media (max-width: 768px) {
    .factory-overview-container {
        padding: 1rem 0.5rem;
        text-align: center;
        color: #fff;
        width: 95;
    }
}