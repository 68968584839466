/* Main Widget */
.tag-battle-widget {
    color: #fff;
    margin: 5px auto;
    margin-bottom: 100px;
}


.login-container {
    height: 100%;
    padding-bottom: 35%;
}



.title {
    margin-top: 3%;
}



/* Explanation Section */
.tag-battle-widget p {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;
}


.message-box {
    flex: 1;

    /* Subtle gradient for depth */
    font-size: 18px;
    margin-bottom: 15%;
    box-shadow: 0 4px 12px rgba(255, 165, 0, 0.3);
}

.navigation-bar {
    display: flex;
    justify-content: space-around;
    overflow-x: auto;
    /* Allows scrolling */
    white-space: nowrap;
    /* Prevents wrapping */
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    scrollbar-width: none;
    /* Hides scrollbar in Firefox */
}

.navigation-bar::-webkit-scrollbar {
    display: none;
}

.navigation-bar button {
    flex: 1;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: background 0.8s;
}

.navigation-bar button {
    flex: none;
    /* Prevent buttons from shrinking */
    padding: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 8px;
    min-width: 100px;
    /* Ensures buttons are not too small */
}

.navigation-bar button:hover,
.navigation-bar button.active {
    background: #1e8fff8f;
    color: rgb(34, 32, 32);
    font-weight: bold;
}


.message-box strong {
    font-size: 20px;
}

.fees-container {
    margin-top: 10px;
}

#sender {
    margin-top: 5%;
    margin-bottom: 5%;
}

.details-title {
    font-size: 20px;
    color: #ffd700;
}

.details-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.details-item {
    flex: 1;
}

/* 📌 Make Prize Tier Glow Consistent */
.prize-tier-box {
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 223, 0, 0.3);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 4px 10px rgba(255, 223, 0, 0.2);
}

/* 🔥 Consistent Glow for All Prize Ranks */
.prize-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    transition: 0.3s;
}

.prize-card:hover {
    box-shadow: 0px 0px 15px rgba(255, 223, 0, 0.6);
    transform: scale(1.05);
}

.prize-highlight {
    box-shadow: 0px 0px 10px rgba(255, 223, 0, 0.4);
    /* Less aggressive glow */
    border-radius: 8px;
}

/* 🏆 Stronger Prize Header Background */
.prize-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.2);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

/* ✨ Add a Subtle Divider Between Header and Prizes */
.prize-tier-values {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

/* 🎖️ Improve Prize Title Visibility */
.prize-tier-title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #FFD700;
    /* Gold */
    text-transform: uppercase;
}

.prize-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.15);
    padding: 12px;
    border-radius: 8px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.prize-card.first {
    box-shadow: 0px 0px 15px rgba(255, 223, 0, 0.8);
    border: 2px solid rgba(255, 223, 0, 1);
}

.prize-card.second {
    box-shadow: 0px 0px 10px rgba(192, 192, 192, 0.6);
    border: 2px solid rgba(192, 192, 192, 0.9);
}

.atypical {
    margin-top: 15px;
}

.prize-card.third {
    box-shadow: 0px 0px 8px rgba(205, 127, 50, 0.6);
    border: 2px solid rgba(205, 127, 50, 0.9);
}

.cooldown-section {
    margin-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.cooldown-grid {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.cooldown-icon {
    font-size: 16px;
    margin-right: 5px;
}

.highlight {
    color: #ffcc00;
    /* Golden highlight */
    font-weight: bold;
}

/* Modal Cleanup */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #24243e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.7);
    width: 60%;
    z-index: 1000;
}

.modal h3 {
    font-size: 22px;
    margin-bottom: 15px;
}

.modal p {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;
}

.modal button {
    width: 100%;
    margin-top: 15px;
}

/* Balances Section */
.balances {
    flex: 1;

}

.balances-title {
    font-size: 16px;
    padding-bottom: 5px;
}

.balances-table {
    gap: 15px;
}


.balances-row {
    display: contents;
    /* Align row with grid cells */

}

.balances-card {

    width: 150px;
}



/* Bottom Section */
.bottom-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    gap: 25px;
}

.message-content {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.sender-info {
    font-size: 14px;
    margin-bottom: 15px;
}

.clickable-address {
    cursor: pointer;
    color: #1E90FF;
    transition: color 0.3s;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.fees-grid {
    gap: 15px;
}

.fee-card {

    width: 150px;
    margin-bottom: 2%;
}

.clickable-address:hover {
    color: #ff5e1e;
}

.stats-box:hover,
.message-box:hover,
.actions-container:hover,
.fee-cell:hover {
    box-shadow: 0 0 20px rgba(255, 223, 0, 0.8);
    /* Softer glow */
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    /* Smooth transition */
    transform: scale(1.02);
    /* Slight pop effect */

}

.stat-header {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.stats-title {
    margin-bottom: 5px;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 25px;
    justify-content: center;
}

.stat-card {

    font-weight: bold;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 223, 0, 0.8);
}

/* Details Section */
.details-container {
    flex: 1;

}

.earnings-box {
    flex: 1;
    min-width: 220px;
    /* Adjust width to match */
}



.message-stats {
    gap: 10px;
    /* Space between rows */
}

.stats-row {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

/* Ensure each stat fits well */
.stats-row p,
.earnings-box p {
    flex: 1;
    font-size: 14px;


    padding: 5px 10px;

}



.liked-confirmation p {
    color: lightgreen;
    font-size: 14px;
}

@media (max-width: 768px) {
    .tag-battle-widget {
        margin-top: 15%;
    }

    .navigation-bar {
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
    }

    .login-container {
        height: 100%;
        margin-top: 25%;

        margin-bottom: 25%;
    }

    .balances-table {
        gap: 2px;
    }

    .stats-box {
        padding: 15px;

        margin-left: 22%;
        margin-top: 5%;
        width: 50%;

    }

    .bottom-container {
        flex-direction: column;
    }

    .fee-cell {
        padding: 4px;
        font-size: 14px;

    }

    .message-box strong {
        font-size: 17px;
    }

    .message-box p {
        font-size: 12px;
    }

}

@media (max-width: 480px) {
    .tag-battle-widget {
        margin-top: 15%;
        margin-bottom: 10%;

    }

    .login-container {
        height: 100%;
        margin-top: 45%;

        margin-bottom: 30%;
    }

    .message-section {
        flex-direction: column;
    }

    .message-box-with-like {
        width: 93%;
        flex-direction: column;
        margin-left: 3%;
    }

    .balances-table {
        gap: 2px;
    }


    .stats-box,
    .message-box {

        margin-left: -1.5%;
        margin-top: 5%;
        width: 93%;

    }

    .bottom-container {
        flex-direction: column;
    }

    .fee-cell {
        padding: 4px;
        font-size: 14px;

    }

    .message-box strong {
        font-size: 17px;
    }

    .message-box p {
        font-size: 12px;
    }

}