.fvw {
    width: 100vw;
}

.w100 {
    width: 100%;
}

.w80 {
    width: 80%;
}

.w60 {
    width: 60%;
}

.w40 {
    width: 40%;
}

.fvh {
    height: 100vh
}