/* Navbar Styles */
.navbar {
    width: 100%;
    height: 6%;
    /* Keep this height constant */
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0vw;
    left: 0vw;
    z-index: 1000;
    flex-wrap: nowrap;
    align-content: center;
    /* Prevent wrapping */
}

.user-info-bals {
    display: flex;
    flex-direction: column;
    max-height: 6%;
}

.user-bals {
    display: block;

    margin-left: 5px;
    font-size: 0.8em;
}


.navbar-left,
.navbar-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 24%;
    /* Prevent wrapping for children */
}

.navbar-right {
    margin-left: 15%;
    justify-content: flex-end;
}

.navbar-center {
    display: flex;
    justify-content: space-between;
    width: auto;

}

.navbar-right {
    margin-right: 30px;
}

.navbar-right h4,
.navbar-right h5 {
    font-size: 0.7rem;
    margin-top: 0px;
    margin-bottom: 0px;
}



.navbar-logo {
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    display: flex;
    /* Use flex for h5 */
    align-items: center;
}

.link {
    margin: 0 15px;
    color: #fff;
    font-size: 2rem;
    text-decoration: none;
    width: auto;
}

.navbar-link-a {
    text-decoration: none;

}





.navbar-fake {
    margin: 0 15px;
    color: #6b6b6b;
    text-decoration: none;
    font-size: 1rem;
}



.navbar-link:hover {
    text-decoration: underline;
}

.navbar a:hover {
    color: #ffee37;
    /* Accent color on hover */
}


.logout-button {
    background-color: #bb5952;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 1rem;

}

.logout-button:hover {
    background-color: #d32f2f;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .navbar {
        width: 100%;
        flex-direction: row;
        /* Stack items vertically on smaller screens */
        height: auto;
        padding: 0;

        /* Adjust height */
        /* Reduce padding */
    }


    .navbar-center {
        width: 85%;
        /* Full width for smaller screens */
        justify-content: center;
        overflow-x: auto;
    }


    .navbar-right {
        width: 20%;
        /* Full width for smaller screens */
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-logo {
        font-size: 1.2rem;
        margin-left: 2vw;
        /* Increase logo size */
    }

    .link {
        margin: 4px;
    }

    .logout-button {
        font-size: 0.9rem;
        /* Smaller font size for button */
        padding: 8px 16px;
        /* Smaller padding */
    }

    .navbar-right h4,
    .navbar-right h5 {
        font-size: 0.4rem;
        /* Reduce size for smaller screens */
    }
}

@media (max-width: 480px) {


    .navbar-right {
        width: 20%;
        /* Full width for smaller screens */
        justify-content: center;
        font-size: 0.5rem;

    }

    .navbar-center {
        width: 70%;
        /* Full width for smaller screens */

        font-size: 0.6rem;

    }


    .navbar-logo {
        font-size: 1rem;
        /* Decrease logo size */
    }

    .link,
    .logout-button {
        font-size: 0.7rem;
        /* Even smaller font size */

        /* Smaller padding */
    }

    .logout-button {
        /* Reduce margin for compactness */
        margin-right: 60px;
        /* Reduce margin for compactness */
    }
}