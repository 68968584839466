.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 100vh;
}

.roadmap {
    width: 100%;
    align-items: center;
}

.alpha {
    text-align: center;
    margin-left: 5%;

    width: 40%;
}

.beta {
    margin-left: 55%;
    width: 40%;
}

.action-button {
    padding: 10px 15px;
    margin: 5px;
    font-size: 1rem;

    /* Gradient */
    color: white;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.action-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.home-link {
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    color: white;
    font-size: 2rem;
    padding: 20px 10px;
    border-radius: 18px;
}

.home-link:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.links {
    display: flex;
    flex-direction: row;
    padding-bottom: 20%;
    gap: 5%;
}

@media (max-width: 768px) {

    .alpha,
    .beta {
        width: 100%;
        /* Expand to full width on small screens */
        margin: 10px 0;
    }

    .home-link {
        font-size: 1rem;
        /* Smaller text on mobile */
        padding: 10px 5px;
    }

    .links {
        padding-bottom: 10%;
        /* Reduced bottom padding */
        gap: 8px;
    }

    h1 {
        /* Adjust heading size */
        margin-top: 15%;
    }
}

/* For very small devices */
@media (max-width: 480px) {
    .home-link {
        font-size: 1rem;

    }

    h1 {
        font-size: 1.5rem;
        /* Adjust heading size */
        margin-top: 25%;
    }
}