.stash-widget {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    width: 60vw;
    margin-left: 20vw;
}



#stash-title {
    text-align: center;
    margin-top: 10%;
}

@media (max-width: 480px) {
    #stash-title {
        margin-top: 40%;
    }

}