.tools {
    width: 100vw;
}


.tools-grid {
    align-items: center;
    align-content: center;
    gap: 5%;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.tool-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 60%;
    border-radius: 8px;
    margin: 40px;
    color: white;
    align-items: center;
}


.tool-container:hover {
    box-shadow: 0 0 20px rgba(255, 223, 0, 0.8);
    /* Softer glow */
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
    transform: scale(1.02);
    /* Slight pop effect */
}

.tool-btn {
    margin: 2%;
    height: 30px;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    /* Gradient */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    color: white;
    border-radius: 25px;
    width: 30%;
}

.tool-btn:hover {

    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 30%;
    text-decoration: none;
    color: white;
}

.tool-description {
    width: 80%;
    padding: 10%;
}

@media (max-width: 768px) {
    .tool-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        border: 3px solid rgb(22, 23, 73);
        border-radius: 8px;
        margin: 10px;
    }

    .tools-grid {
        align-items: center;
        align-content: center;
        gap: 5%;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .tool-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 60%;
        border: 3px solid rgb(22, 23, 73);
        border-radius: 8px;
        margin: 10px;

    }

    .tools-grid {
        align-items: center;
        align-content: center;
        gap: 5%;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .tool-btn {
        margin: 2%;
        height: 20px;
        border-radius: 25px;
        width: 60%;
        margin-left: 0%;

    }

    #tools-title {
        margin-top: 25%;
    }
}