.staking {
    width: 100%;
    border-radius: 25px;
    height: 100%;
    align-items: center;
}


.staking-title {
    margin-top: 0%;
}

.staking p {
    font-size: 1rem;
    font-weight: 400;
    color: #cccccc;
}

.staking h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #e0e0e0;
}




.connect-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.connect-button:hover {
    background-color: #45a049;
}

.card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 2rem;
    margin: 20px 0;

}

.card {
    border-radius: 10px;
    padding: 1.5rem;
    width: calc(40% - 10px);
    /* Two cards per row */
    min-width: 300px;
    /* Prevent too small cards on resize */
    text-align: left;
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    /* Slightly stronger shadow */
    background: linear-gradient(145deg, #2e2e30, #3a3a3c);
    /* Subtle gradient for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 0 20px rgba(255, 223, 0, 0.8);
    /* Softer glow */
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
    transform: scale(1.02);
    /* Slight pop effect */
}

.card h3 {
    margin-bottom: 15px;
    text-align: center;
}

.card p {
    margin: 5px 0;
    font-size: 1rem;
    color: white;
}

.stake-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 200px;
    margin-left: 24%;
}

.action-button {
    padding: 10px 15px;
    margin: 5px;
    font-size: 1rem;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    /* Gradient */
    color: white;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.action-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}



.status-message {
    margin-top: 20px;
    font-size: 1rem;
    color: blue;
}

#input,
.admin-panel {
    margin-left: calc(30% - 10px);
}

.admin-panel {
    align-content: center;
}

#input {
    margin-bottom: 20px;
}

.button-case {
    display: flex;
    justify-content: space-evenly;
}


@media (max-width: 768px) {
    .card-container {
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        margin: 20px 12%;
    }

    .admin-panel {
        margin-left: 12%;
    }

    #input {
        margin-left: 12%;
    }

    .stake-input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 55%;
        margin-left: 1%;
    }

}

@media (max-width: 480px) {
    .staking {
        width: 90%;
        align-items: center;
    }

    .card-container {
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        margin: 20px 0;
        align-items: center;
    }

    .card {
        border-radius: 10px;
        padding: 0.5rem;
        width: calc(50% - 10px);
        /* Two cards per row */
        min-width: 300px;
        /* Prevent too small cards on resize */
        text-align: left;
    }

    .admin-panel {
        margin-left: 7%;
    }



    .stake-input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 55%;
        margin-left: 1%;
    }

    .staking p {
        font-size: 0.8rem;
        font-weight: 400;
    }

    .staking h3 {
        font-size: 1.2rem;
        font-weight: 600;
    }

    #input {
        margin-left: 0;
    }
}

@media (max-width: 360px) {
    .staking {
        width: 90%;
        align-items: center;
        margin: -8% -3%;
    }

    .card-container {
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        margin: 20px 0;
        align-items: center;
    }

    .card {
        padding: 0.5rem;
        /* Two cards per row */
        min-width: 300px;

    }

    .admin-panel {
        margin-left: 0;
        align-items: center;
    }

    #input {
        margin-left: 0;
    }

    .stake-input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 45%;
        margin-left: 1%;
    }

    .staking p {
        font-size: 0.8rem;
        font-weight: 400;
    }

    .staking h3 {
        font-size: 1.2rem;
        font-weight: 600;
    }
}