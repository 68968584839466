@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import './globals/size.css';

:root {
    /* Colors */
    --primary-color: #1E90FF;
    --secondary-color: #2e2e30;
    --highlight-color: rgba(255, 223, 0, 0.8);
    --text-color: #ffffff;
    --text-muted: rgba(255, 255, 255, 0.6);
    --text-warning: #ffcc00;
    --text-error: #ff4d4d;

    /* Font Sizes */


    /* Font Weights */
    --font-light: 300;
    --font-regular: 400;
    --font-bold: 600;
    --font-extrabold: 700;

    /* Borders & Shadows */
    --border-radius: 10px;
    --shadow-light: 0 4px 15px rgba(0, 0, 0, 0.4);
    --shadow-hover: 0 0 20px var(--highlight-color);
}