/* Progress Bar Styles */
.progress-bar-container {
  width: 80%;
  background: linear-gradient(145deg, #3a3b3c, #2c2d2e);
  /* Gradient for depth */
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: auto;
  height: 30px;
  margin-bottom: 2%;
  border: 2px solid #1E90FF;
  /* Subtle outline */
}

.progress-bar {
  background: linear-gradient(90deg, #b400ff, #6a00ff);
  box-shadow: 0 0 12px #b400ff;
  /* Light glow */
  height: 100%;
  transition: width 0.5s ease-in-out;
  /* Glow Effect */
}

.progress-bar-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  top: 50%;
  transform: translateY(-125%);
  /* Proper centering */
}

.progress-bar-container p {
  color: white;
}