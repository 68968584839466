.fee-table,
.stash-table {
    margin-top: 1rem;
    text-align: center;
}

.fee-table h4,
.stash-table h4 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.fee-table table,
.stash-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    font-size: 0.9rem;
}

.fee-table th,
.fee-table td,
.stash-table th,
.stash-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.stash-table td,
.stash-table th {
    color: white;
}

.fee-table th,
.stash-table th {
    background: linear-gradient(145deg, #4c4c58, #3a3a3c);
    font-weight: bold;
}

.fee-table td,
.stash-table td {
    background: linear-gradient(145deg, #595969, #3a3a3c);
}

.fee-table td:hover {
    font-size: 1.2rem;
}

@media (max-width: 480px) {

    .stash-table table {
        width: 100%;
        border-collapse: collapse;
        margin: auto auto;
        font-size: 0.9rem;
    }



}