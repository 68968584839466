/* customModal.css */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Default background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-modal-content {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4); /* Slightly stronger shadow */
    background: linear-gradient(145deg, #2e2e30, #3a3a3c); /* Subtle gradient for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

.custom-modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

.custom-modal-close-btn:hover {
    color: red;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
