@import './global.css';


.tabs {
    margin: auto;
}

.flexColumnCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}


.mt5 {
    margin-top: 5%;
}

.mb5 {
    margin-bottom: 5%;
}

.pad10 {
    padding: 10px;
}

.pad15 {
    padding: 15px;
}

.pad20 {
    padding: 20px;
}

p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-color);
}

.myh1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--text-color);
}


.myh3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
}

.glass {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.02));
    box-shadow: inset 0 2px 5px rgba(255, 255, 255, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: var(--border-radius);
}

/* Global Text Styles */
.text {
    font-family: 'Inter', sans-serif;
}

.text-primary {
    color: var(--primary-color);
}

.text-secondary {
    color: var(--text-muted);
}

.text-warning {
    color: var(--text-warning);
}

.text-error {
    color: var(--text-error);
}

/* Text Alignment */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

/* Utility Classes */
.muted {
    color: var(--text-muted);
}

.bold {
    font-weight: var(--font-bold);
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

/* Universal Box */
.box {
    box-shadow: var(--shadow-light);
    background: linear-gradient(145deg, var(--secondary-color), #3a3a3c);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 2px solid var(--primary-color);
    border-radius: var(--border-radius);
    padding: 15px;
    text-align: center;
}

.box:hover {
    box-shadow: var(--shadow-hover);
}

/* Flex Center */
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Grid Layout */
.grid-container {
    display: grid;
    gap: 15px;
}

.gap {
    gap: 50px
}

/* Button */
.button {
    padding: 15px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s;
}

.button:hover {
    background: var(--primary-color);
    color: var(--secondary-color);
}

/* Input */
.input {
    padding: 10px;
    border: 2px solid var(--primary-color);
    border-radius: var(--border-radius);
    background: #222;
    color: var(--text-color);
    width: 90%;
    outline: none;
    transition: 0.3s ease-in-out;
}

.input:focus {
    border-color: #8a2be2;
    box-shadow: 0 0 10px #8a2be2;
}

.divcolor {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    /* Slightly stronger shadow */
    background: linear-gradient(145deg, #2e2e30, #3a3a3c);
    /* Subtle gradient for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 2px solid #1E90FF;
    border-radius: 10px;
}

.divcolor:hover {
    box-shadow: 0 0 20px rgba(255, 223, 0, 0.8);
}