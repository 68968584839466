.widget-container {
  max-width: 600px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 200px;
}

.widget-container h2 {
  text-align: center;
  color: #333333;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.widget-container h3 {
  color: #555555;
  margin: 15px 0 10px;
  font-size: 1.2rem;
}

.widget-container h4 {
  color: #444444;
  margin: 10px 0;
}

input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 1rem;
}



button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}



.actions-section {
  margin: 15px 0;
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fafafa;
}

.actions-section input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.actions-section button {
  margin-top: 5px;
}

footer {
  text-align: center;
  font-size: 0.8rem;
  color: #888888;
  margin-top: 20px;
}