.presale {
    text-align: center;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;

}

.main {
    width: 60%;

}



.sale-info,
.sale-info2 {
    border: 2px solid black;
    display: flex;
    gap: 10%;
    padding-left: 5%;
    border-radius: 18px;
    margin-bottom: 1%;
    margin-top: 1%;
    background-color: rgb(22, 23, 73);
    color: rgb(255, 255, 255);
    font-size: 1rem;
}


.sale-info2 {
    font-size: 0.8rem;
    gap: 25%;
    align-content: space-evenly;
}

.container {
    width: 60%;
    display: flex;
    flex-direction: column;

}

#info {
    text-align: left;
    padding-left: 2%;
    padding-top: 1%;
    border-radius: 18px 0px 0px 18px;
    width: 50%;
    min-height: 60vh;
    background-color: #4fbbda;

    /*background-image: url('../pages/test2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    */
}


#buy {
    align-items: center;
    width: 50%;
    height: 100%;

}

.input-field {
    width: 80%;
}

.buy {
    background-color: rgb(20, 82, 197);
    color: white;
    border: none;
    border-radius: 8px;
    width: 50%;
    height: 10%;
}


.buy:hover {
    background-color: rgb(96, 191, 255);
    color: black;
    font-size: large;
}

.presale-box {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    align-items: center;
    border-radius: 18px;
    width: 100%;
}

.buy-h {
    margin-bottom: 15%;
}

.buy {
    margin-top: 5%;
}

.progress {
    display: "flex";
    align-items: "center";
    gap: "10px";
}

@media (max-width: 768px) {
    .presale {
        padding-bottom: 30%;
    }

    .login-btn {
        margin-top: 25%;
        margin-bottom: 5%;
    }

    .sale-info2 {
        gap: 5%;
        font-size: 0.7rem;
    }

    .presale b {
        font-size: 0.9rem;
    }

    .title {
        margin-top: 25%;
    }
}

@media (max-width: 480px) {
    .presale {
        padding-bottom: 40%;
    }

    .login-btn {
        margin-top: 30%;
        margin-bottom: 10%;
    }

    .main {
        width: 80%;
    }

    .login-btn {
        margin-top: 30%;

    }

    .title {
        margin-top: 35%;
    }

    h1 {
        font-size: 1.5rem;
        /* Adjust heading size */
    }

    .input-field {
        width: 80%;
    }

    .sale-info,
    .sale-info2 {
        font-size: 0.8rem;
    }

    .sale-info2 {
        gap: 3%;
    }
}