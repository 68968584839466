/* General container styling */
.token-factory {
    max-width: 100%;
    padding: 2rem;
    text-align: center;
    color: white;
    border-radius: 10px;
    padding: 3rem;

}

/* Title Styling */
.token-factory h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: white;
    margin-top: 5%;
}

/* Factory info styling */
.factory-info p {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    color: #f0f0f0;

}

.factory-info {
    width: 70%;
    margin: auto;
}

.view-tokens-button:hover,
.create-token-button:hover,
.admin-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Token Selection Cards */
.token-card {

    padding: 2rem;
    border-radius: 10px;
    color: white;
    text-align: center;
}

.build {
    width: 50%;
    margin: auto;
}

.token-card p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}



/* Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: linear-gradient(145deg, #2e2e30, #3a3a3c);
    /* Subtle gradient for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 1.5rem;
    border-radius: 10px;
    width: 50%;
    text-align: center;
    border: 2px solid #1E90FF;
    margin-top: 5%;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content ::placeholder {
    color: white;
}

.modal-content input[type="text"],
.modal-content input[type="number"] {
    width: calc(100% - 20px);
    padding: 8px;
    margin: 10px 0;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #787d8a;
    color: white;

}


/* Admin Panel Styling */
.admin-panel {
    margin-top: 3rem;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    text-align: center;
}

.admin-panel h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
}

.admin-button {
    background: #ff9800;
    padding: 10px 15px;
    margin: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
}

.admin-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.payment-selector {
    height: 50%;
    margin: auto;
}

.hori h3 {
    margin: auto;
}

/* 📱 Responsive: Mobile Layout Adjustments */
@media (max-width: 768px) {
    .token-factory {
        padding: 1rem;
        width: 100%;
    }

    .token-factory h1 {
        font-size: 1.8rem;
        margin-top: 2rem;
    }

    .factory-info {
        width: 80%;
    }

    .factory-info p {
        font-size: 0.6rem;
    }

    .build {
        width: 100%;
        padding: 0 1rem;
    }

    .token-card {
        width: 80%;
    }

    .modal-content {
        width: 80%;
        margin-top: 10%;
        padding: 1rem;
    }

    .modal-content input[type="text"],
    .modal-content input[type="number"] {
        width: 80%;
        font-size: 0.95rem;
    }



    .admin-panel {
        padding: 1rem;
    }

    .admin-panel h2 {
        font-size: 1.5rem;
    }

    .admin-button {
        width: 100%;
        margin: 0.5rem 0;
        font-size: 1rem;
    }

    .payment-selector {
        width: 100%;
        padding: 0 1rem;
    }

    .hori h3 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.9rem;
    }

    .links,
    .socials-grid {
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
}