body {
  font-family: "Poppins", sans-serif;

}

.App {
  text-align: center;

  background: radial-gradient(circle at 100%, #1c1c1e, #292929 40%, #3ea5ff 75%, #1c1c1e 75%);


  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
}

input {
  background: linear-gradient(145deg, #3c3c3f, #424245);
  color: white;
}

button {
  padding: 10px 15px;
  margin: 5px;
  font-size: 1rem;
  background: linear-gradient(90deg, #6a11cb, #2575fc);
  /* Gradient */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;

}

button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #e0e0e0;
}

p {
  font-size: 1rem;
  font-weight: 400;
  color: #cccccc;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {}


.hori {
  display: flex;
  flex-direction: row;
}