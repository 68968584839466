.token-details {
    width: 40%;
    margin: 5% auto;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    /* Slightly stronger shadow */
    background: linear-gradient(145deg, #2e2e30, #3a3a3c);
    /* Subtle gradient for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 12px;
    color: #fff;
    text-align: center;
    border: 2px solid #1E90FF;
}

.token-details h1 {
    font-size: 26px;
    margin-bottom: 10px;
    color: white;
    /* Yellow for emphasis */
}

.token-details p {
    font-size: 16px;
    margin: 5px 0;
}

.token-details strong {
    color: #2575fc;
}

.token-details .admin-actions {
    margin-top: 20px;
    padding: 15px;
    border-top: 1px solid #444;
}

.token-details h3 {
    margin-top: 20px;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
}

.token-details h4 {
    color: #2575fc;
}


.token-details input {
    width: 90%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #555;
    background: #787d8a;
    color: #fff;
    border-radius: 5px;
    text-align: center;
}

.token-details ::placeholder {
    color: white;
}

.action-section {
    margin-bottom: 10px;
    width: 94%
}

.action-section h4 {
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}

.action-content {
    padding: 10px;
    background: #f4f4f4;
    border-radius: 5px;
    margin-top: 5px;
}

/* MODAL OVERLAY */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    background: radial-gradient(circle at 100%, #1c1c1e, #292929 40%, #3ea5ff 75%, #1c1c1e 75%);
}

/* MODAL CONTENT */
.modal-contents {
    padding: auto;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6);
    text-align: center;
    position: relative;
    background: linear-gradient(145deg, #3c3c3f, #424245);
    border: 2px solid #1E90FF;
}

/* CLOSE BUTTON */
.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.social-links {
    display: flex;
    gap: 12%;

}

.social-button-container {
    position: relative;
    display: inline-block;
    margin: auto;
}

.social-active {
    color: #ffffff;
    transition: color 0.2s ease-in-out;
}

.social-active:hover {
    color: #4a90e2;
}

.social-disabled {
    color: #888888;
    pointer-events: none;
    cursor: not-allowed;
}

.tooltip {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    white-space: nowrap;
}

.social-button-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 768px) {
    .token-details {
        width: 80%;
    }

    .social-active,
    .social-disabled {
        gap: 25px
    }
}