.tokens {
    text-align: center;
    width: 100%;

}

.tokens h2 {
    width: 40%;
    color: white;
    margin: auto;
}

.token-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;

}

.token-card {
    gap: 5px
}

#all-tokens {
    margin-top: 10%;
}

.token-selector {
    margin: 1.5rem 0;
    text-align: center;
}

.token-selector select {
    padding: 8px;
    border-radius: 8px;
    font-size: 16px;
    background: #1e1e2f;
    color: white;
    border: 1px solid #4a4aff;
    cursor: pointer;
}

.token-selector select:hover {
    border-color: #ff00ff;
}

.token-selector label {
    display: block;
    font-size: 18px;
    margin-bottom: 8px;
}

@media (max-width: 768px) {
    #all-tokens {
        margin-top: 25%;
    }

    .token-cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5%;
        width: 80%;
        margin: auto;
    }
}