.text-with-image p {
    font-size: 2rem;
}

@media (max-width: 768px) {
    .text-with-image p {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .text-with-image p {
        font-size: 1rem;
    }

    .text-with-image {

        display: flex;
        flex-direction: column;
    }
}